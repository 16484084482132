export enum QuestionType {
  RATING = 'rating',
  RADIO = 'radio',
  TEXTBOX = 'textbox',
}

export interface ReviewAnswer {
  id?: string;
  category?: string;
  type: QuestionType;
  question: string;
  options: string;
  answer: string;
  order_sort?: string;
}

export const BadgeColors = {
  yes: 'badge-yes',
  no: 'badge-no',
  'n/a': 'badge-not-applicable',
};
