
import { defineComponent } from 'vue';
import { Getters as BusinessGetters } from '@/store/enums/BusinessEnums';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import { mapGetters } from 'vuex';
import text from '@/utils/text';
import Avatar from '@/views/new-design/pages/ViewTenantReview/Avatar.vue';
import { Getters } from '@/store/enums/TenantReviewEnums';

export default defineComponent({
  props: {
    currentTenantReview: {
      default: () => {
        return {} as any;
      },
    },
  },
  components: { BusinessLogo, Avatar },
  computed: {
    ...mapGetters({
      getBusinessLogo: BusinessGetters.GET_AGENT_BUSINESS_LOGO,
      agentAvatar: Getters.GET_AGENT_AVATAR,
    }),
    hasEmail() {
      return this.currentTenantReview.user.email;
    },
    hasMobileNumber() {
      return this.currentTenantReview.user.mobile_number;
    },
    address() {
      return this.currentTenantReview.user.roles[0].role == 'Admin'
        ? null
        : `${this.currentTenantReview?.user?.business_address_line}, ${this.currentTenantReview?.user?.business_city}, ${this.currentTenantReview?.user?.business_state}, ${this.currentTenantReview?.user?.business_post_code}`;
    },
    hasLogo() {
      return (
        (this.currentTenantReview as any)?.user?.business_logo &&
        (this.currentTenantReview as any)?.user?.business_logo.trim() != ''
      );
    },
    formattedAbn() {
      return this.currentTenantReview?.user?.abn
        ? text.formatABN(this.currentTenantReview?.user?.abn)
        : null;
    },
    formattedAcn() {
      return this.currentTenantReview?.user?.acn
        ? text.formatACN(this.currentTenantReview?.user?.acn)
        : null;
    },
  },
});
